<template>
    <div class="">

        <!-- Page Banner Start-->
        <PageBanner
            :pgLoading="pgLoading"
            :background="(page.image) ? page.image.url : ''"
            :currentPage="($i18n.locale == 'ar') ? page.title_ar : page.title_en">
        </PageBanner>
        <!-- Page Banner End -->




        <!-- Listing Start -->
        <section id="listing1" class="listing1 padding_top">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-12 col-xs-12">

                <div v-if="pgLoading" class="row">
                  <div class="col-sm-6"
                      v-for="(row, index) in 6"
                      :key="index">

                      <PropertyLoading></PropertyLoading>

                  </div>
                </div>

                <div v-if="!pgLoading" class="row">
                    <div class="col-sm-6"
                        v-for="(item, index) in items"
                        :key="index">
                        
                        <PropertyCard :item="item"></PropertyCard>

                    </div>
                </div>
                
                <LoadMoreButton
                    :pgLoading="pgLoading"
                    :hasMore="hasMore"
                    :btnLoading="btnLoading"
                    
                    v-on:loadMoreClicked="handleLoadMore">
                </LoadMoreButton>

              </div>


                <aside class="col-md-4 col-xs-12">
                            
                    <ContactForm :pgLoading="pgLoading"></ContactForm>

                    <FeaturedAside 
                            :title="'Featured Properties'"
                            :refs="'properties'"
                            :paginate="3">
                    </FeaturedAside>

                    <BestDealAside 
                            :title="'Best Deal Properties'"
                            :refs="'properties'"
                            :paginate="3">
                    </BestDealAside>
                </aside>

            </div>
          </div>
        </section>
        <!-- Listing end -->
        
        

    </div>
</template>


<script>    
export default {
    name: 'List',
    components: {
        PageBanner: () => import('@/components/PageBanner.vue'),
        PropertyLoading: () => import('@/components/PropertyLoading.vue'),
        PropertyCard: () => import('@/components/PropertyCard.vue'),
        LoadMoreButton: () => import('@/components/LoadMoreButton.vue'),
        ContactForm: () => import('@/components/ContactForm.vue'),
        FeaturedAside: () => import('@/components/FeaturedAside.vue'),
        BestDealAside: () => import('@/components/BestDealAside.vue'),
    },
    data(){
        return {
            locale: 'en',
            pgLoading: true,
            hasMore: false,
            btnLoading: false,
            btnClicked: false,
            pageNo: 1,

            page: '',
            items: [],
        }
    },
    metaInfo() {
        return {
            title: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_description : this.page.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_keywords : this.page.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    mounted() {},
    created() {

        this.fetchData();
    },
    methods: {
          
        
        fetchData(loading=false) {
            if(loading) { 
                this.pgLoading = true;
            }
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            let vm = this;
            const options = {
                url: window.baseURL+'/properties',
                method: 'GET',
                data: {},
                params: {
                    page: this.pageNo,
                    filter_by: 'category',
                    filter: this.$route.query.type,
                    paginate: 6
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.btnLoading = false;
                this.page = res.data.page;
                
                //this.items = res.data.items;

                if(this.btnClicked) {
                    this.items = vm.items.concat(res.data.items);
                }else{
                    this.items = res.data.items;
                }

                this.btnClicked = false;

                if(res.data.paginate.next_page > 0) {
                    this.hasMore = true;
                } else {
                    this.hasMore = false;
                }

            })
            .catch(() => {})
            .finally(() => {});
        },


        handleLoadMore() {
            this.btnClicked = true;
            this.btnLoading = true;
            this.pageNo++;
            this.fetchData();
        },
          
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
